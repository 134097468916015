import fights from './fights'
import robots from './robots'
import users from './users'
import teams from './teams'
import categories from './categories'
import roles from './roles'
import main from './main'

export default {
  fights,
  robots,
  users,
  teams,
  categories,
  roles,
  main
}
