import axios from '@/axios'

const actions = {
  setTeams: ({ commit }) => {
    return axios
      .teams
      .fetch(
        '/teams'
      )
      .then((response) => {
        return commit('teams', response)
      })
      .catch(function (error) {
        console.log('-----------error-----------', error)
      })
  },
  setCreateTeam: ({ dispatch }, team) => {
    dispatch('main/setLoading', true, { root: true })
    return axios
      .teams
      .post
      .create(team)
      .then((response) => {
        return dispatch('main/setLoading', false, { root: true })
      })
      .catch(function (error) {
        console.log('-----------error-----------', error)
        dispatch('main/setLoading', false, { root: true })
      })
  }
}

export default actions
