// import Vue from 'vue'

const getters = {
  getTeams: ({
    teams
  }) =>
    teams
}

export default getters
