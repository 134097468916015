import Vue from 'vue'

const mutations = {
  categories: (state, categories) => {
    return Vue.set(
      state,
      'categories',
      categories
    )
  }
}

export default mutations
