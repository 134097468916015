import http from '@/axios/http'

const post = ({ headers, data, url }) => {
  headers = {
    'Content-Type': 'application/json',
    ...headers
  }
  if (localStorage.getItem('token')) {
    headers.Auth = localStorage.getItem('token')
  }

  return http
    .post(
      url,
      data,
      { headers }
    )
    .then(response => response.data)
}

export const getFromEvent = (eventId) => {
  return post({
    url: '/get_fights_info',
    data: { ...eventId }
  })
}

export const initFight = (ids) => {
  return post({
    url: '/initialize_fight',
    data: { ...ids }
  })
}

export const infoJudgesScoresFight = (fightId) => {
  return post({
    url: '/get_judges_scores',
    data: { ...fightId }
  })
}

export const finishFight = (fightId) => {
  return post({
    url: '/end_fight',
    data: { ...fightId }
  })
}

export const enterFight = (ids) => {
  return post({
    url: '/enter_fight',
    data: { ...ids }
  })
}

export const enterRoundJudgeFight = (ids) => {
  return post({
    url: '/enter_fight_judge',
    data: { ...ids }
  })
}

export const generateKeys = (info) => {
  return post({
    url: '/generate_brackets',
    data: { ...info }
  })
}

export const categoryKeys = (ids) => {
  return post({
    url: '/get_brackets',
    data: { ...ids }
  })
}

export const fightAggressivity = (info) => {
  return post({
    url: '/post_judge_aggressivity',
    data: { ...info }
  })
}

export const fightDamage = (info) => {
  return post({
    url: '/post_judge_damage',
    data: { ...info }
  })
}

export const changeFightsTime = (fights) => {
  return post({
    url: '/change_fight_time',
    data: fights
  })
}
