// import Vue from 'vue'

const getters = {
  getCategories: ({
    categories
  }) =>
    categories
}

export default getters
