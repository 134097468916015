// import Vue from 'vue'

const getters = {
  getLoading: ({
    status
  }) =>
    status
}

export default getters
