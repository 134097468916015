import * as fights from './fights'
import * as robots from './robots'
import * as users from './users'
import * as teams from './teams'
import * as categories from './categories'
import * as roles from './roles'

export default {
  fights,
  robots,
  users,
  teams,
  categories,
  roles
}
