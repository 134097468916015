import axios from '@/axios'

const actions = {
  setFights: ({ dispatch, commit }, eventId) => {
    dispatch('main/setLoading', true, { root: true })
    return axios
      .fights
      .post
      .getFromEvent(eventId)
      .then((response) => {
        commit('fights', response)
        return dispatch('main/setLoading', false, { root: true })
      })
      .catch(function (error) {
        console.log('-----------error-----------', error)
        dispatch('main/setLoading', false, { root: true })
      })
  },

  setInitFight: ({ commit, dispatch }, ids) => {
    dispatch('main/setLoading', true, { root: true })
    return axios
      .fights
      .post
      .initFight(ids)
      .then((response) => {
        commit('currentFight', response)
        return dispatch('main/setLoading', false, { root: true })
      })
      .catch(function (error) {
        console.log('-----------error-----------', error)
        dispatch('main/setLoading', false, { root: true })
      })
  },

  setInfoJudgeScoreFight: ({ commit, dispatch }, fightId) => {
    dispatch('main/setLoading', true, { root: true })
    return axios
      .fights
      .post
      .infoJudgesScoresFight(fightId)
      .then((response) => {
        commit('infoJudgesScoresFight', response)
        return dispatch('main/setLoading', false, { root: true })
      })
      .catch(function (error) {
        console.log('-----------error-----------', error)
        dispatch('main/setLoading', false, { root: true })
      })
  },

  setFinishFight: ({ commit, dispatch }, fightId) => {
    dispatch('main/setLoading', true, { root: true })
    return axios
      .fights
      .post
      .finishFight(fightId)
      .then((response) => {
        return dispatch('main/setLoading', false, { root: true })
      })
      .catch(function (error) {
        console.log('-----------error-----------', error)
        dispatch('main/setLoading', false, { root: true })
      })
  },

  setEnterFight: ({ commit, dispatch }, ids) => {
    dispatch('main/setLoading', true, { root: true })
    return axios
      .fights
      .post
      .enterFight(ids)
      .then((response) => {
        commit('currentFight', response)
        return dispatch('main/setLoading', false, { root: true })
      })
      .catch(function (error) {
        console.log('-----------error-----------', error)
        dispatch('main/setLoading', false, { root: true })
      })
  },

  setEnterRoundJudgeFight: ({ commit, dispatch }, ids) => {
    dispatch('main/setLoading', true, { root: true })
    return axios
      .fights
      .post
      .enterRoundJudgeFight(ids)
      .then((response) => {
        commit('currentFight', response)
        return dispatch('main/setLoading', false, { root: true })
      })
      .catch(function (error) {
        console.log('-----------error-----------', error)
        dispatch('main/setLoading', false, { root: true })
      })
  },

  setGenerateKeys: ({ commit, dispatch }, info) => {
    dispatch('main/setLoading', true, { root: true })
    return axios
      .fights
      .post
      .generateKeys(info)
      .then((response) => {
        return dispatch('main/setLoading', false, { root: true })
      })
      .catch(function (error) {
        console.log('-----------error-----------', error)
        dispatch('main/setLoading', false, { root: true })
      })
  },

  setfightAggressivity: ({ commit, dispatch }, info) => {
    dispatch('main/setLoading', true, { root: true })
    return axios
      .fights
      .post
      .fightAggressivity(info)
      .then((response) => {
        return dispatch('main/setLoading', false, { root: true })
      })
      .catch(function (error) {
        console.log('-----------error-----------', error)
        dispatch('main/setLoading', false, { root: true })
      })
  },

  setFightDamage: ({ commit, dispatch }, info) => {
    dispatch('main/setLoading', true, { root: true })
    return axios
      .fights
      .post
      .fightDamage(info)
      .then((response) => {
        return dispatch('main/setLoading', false, { root: true })
      })
      .catch(function (error) {
        console.log('-----------error-----------', error)
        dispatch('main/setLoading', false, { root: true })
      })
  },

  setCategoryKeys: ({ commit, dispatch }, ids) => {
    dispatch('main/setLoading', true, { root: true })
    return axios
      .fights
      .post
      .categoryKeys(ids)
      .then((response) => {
        commit('currentKeys', response)
        return dispatch('main/setLoading', false, { root: true })
      })
      .catch(function (error) {
        console.log('-----------error-----------', error)
        dispatch('main/setLoading', false, { root: true })
      })
  },

  setChangeFightsTime: ({ dispatch }, fights) => {
    dispatch('main/setLoading', true, { root: true })
    return axios
      .fights
      .post
      .changeFightsTime(fights)
      .then((response) => {
        return dispatch('main/setLoading', false, { root: true })
      })
      .catch(function (error) {
        console.log('-----------error-----------', error)
        dispatch('main/setLoading', false, { root: true })
      })
  },

  setCleanCurrentFight: ({ commit, dispatch }) =>
    commit('currentFight', {})
}

export default actions
