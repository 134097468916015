import axios from '@/axios'

const actions = {
  setRoles: ({ commit }) => {
    return axios
      .roles
      .fetch(
        '/roles'
      )
      .then((response) => {
        return commit('roles', response)
      })
  }
}

export default actions
