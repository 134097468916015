import http from '@/axios/http'
// import store from '@/vuex'

const post = ({ headers, data, url }) => {
  headers = {
    'Content-Type': 'application/json',
    ...headers
  }
  if (localStorage.getItem('token')) {
    headers.Auth = localStorage.getItem('token')
  }

  return http
    .post(
      url,
      data,
      { headers }
    )
    .then(response => response.data)
}

export const create = (user) => {
  return post({
    url: '/user_signup',
    data: { ...user }
  })
}

export const addEventRole = (info) => {
  return post({
    url: '/create_user_event_role',
    data: { ...info }
  })
}

export const signIn = (user) => {
  return post({
    url: '/user_signin',
    data: { ...user }
  })
}

export const get = (user) => {
  return post({
    url: '/get_user',
    data: { ...user }
  })
}

export const getEventRoles = (id) => {
  return post({
    url: '/get_user_event_roles',
    data: { ...id }
  })
}
