// CYBER-ATOMIC
const Navbar = () => import(/* webpackChunkName: "Navbar" */'./Navbar')
const Icon = () => import(/* webpackChunkName: "Icon" */'./Icon')
const Button = () => import(/* webpackChunkName: "Button" */'./Button')
const Select = () => import(/* webpackChunkName: "Select" */'./Select')
const Check = () => import(/* webpackChunkName: "Check" */'./Check')
const TableIcons = () => import(/* webpackChunkName: "TableIcons" */'./TableIcons')
const Loading = () => import(/* webpackChunkName: "Loading" */'./Loading')
const TournamentBrackets = () => import(/* webpackChunkName: "TournamentBrackets" */'./TournamentBrackets')

export {
  Navbar,
  Icon,
  Button,
  Select,
  Check,
  TableIcons,
  Loading,
  TournamentBrackets
}
