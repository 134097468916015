import Vue from 'vue'

const mutations = {
  fights: (state, fights) => {
    return Vue.set(
      state,
      'fights',
      fights
    )
  },

  currentFight: (state, figth) => {
    return Vue.set(
      state,
      'currentFight',
      figth
    )
  },

  infoJudgesScoresFight: (state, scores) => {
    const fight = state.currentFight
    fight.push(scores)
    return Vue.set(
      state,
      'currentFight',
      fight
    )
  },

  currentKeys: (state, keys) => {
    return Vue.set(
      state,
      'currentKeys',
      keys
    )
  }
}

export default mutations
