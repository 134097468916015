import axios from '@/axios'

const actions = {
  setCategories: ({ commit, dispatch }) => {
    return axios
      .categories
      .fetch(
        '/categories'
      )
      .then((response) => {
        commit('categories', response)
      })
  }
}

export default actions
