// import Vue from 'vue'

const getters = {
  getRobots: ({
    robots
  }) =>
    robots,

  getCurrentRobot: ({
    currentRobot
  }) =>
    currentRobot
}

export default getters
