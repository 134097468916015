// import Vue from 'vue'

const getters = {
  getEventRoles: ({
    roles
  }) =>
    roles
}

export default getters
