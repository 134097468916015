// import Vue from 'vue'

const getters = {
  getUser: ({
    user
  }) =>
    user
}

export default getters
