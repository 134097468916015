const state = {
  user: {
    name: '',
    email: '',
    id: '',
    roles: []
  }
}

export default state
