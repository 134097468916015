import Vue from 'vue'

const mutations = {
  initialiseStore: (state) => {
    const user = localStorage.getItem('user')
    if (user !== undefined && user !== null && user !== 'undefined') {
      return Vue.set(
        state,
        'user',
        JSON.parse(user)
      )
    }
  },
  user: (state, user) => {
    localStorage.setItem('user', JSON.stringify(user))
    return Vue.set(
      state,
      'user',
      user
    )
  },
  userId: (state, id) => {
    const user = state.user
    user.id = id
    return Vue.set(
      state,
      'user',
      user
    )
  },
  userRoles: (state, user) => {
    return Vue.set(
      state,
      'user',
      user
    )
  },
  cleanStore: (state) => {
    Vue.set(
      state,
      'user',
      {}
    )
  }
}

export default mutations
