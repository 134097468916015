import Vue from 'vue'

const mutations = {
  roles: (state, roles) => {
    return Vue.set(
      state,
      'roles',
      roles
    )
  }
}

export default mutations
