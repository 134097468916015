<template lang="pug">
.main
  Loading(
      v-if="getLoading"
  )
  Navbar(
    v-if="showNavbar"
    )
  RouterView(
    name="content"
  )
</template>

<script>

import {
  mapActions,
  mapGetters
} from 'vuex'

import {
  Navbar,
  Loading
} from '@/components/atoms'

export default {
  data: () => ({
    showNavbar: true
  }),
  created () {
    this.setInitialiseStore()
  },
  mounted () {
    const currentRoute = this.$router.currentRoute.name
    if (currentRoute === 'SignIn' || currentRoute === 'SignUp') {
      this.showNavbar = false
    } else {
      this.showNavbar = true
    }
  },
  updated () {
    const currentRoute = this.$router.currentRoute.name
    if (currentRoute === 'SignIn' || currentRoute === 'SignUp') {
      this.showNavbar = false
    } else {
      this.showNavbar = true
    }
  },
  computed: {
    ...mapGetters('main', [
      'getLoading'
    ])
  },
  methods: {
    ...mapActions('users', [
      'setInitialiseStore'
    ])
  },
  components: {
    Navbar,
    Loading
  }
}
</script>

<style lang="sass">
@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap')

html
  font-family: Lato, sans-serif
  padding: 0
  margin: 0
  min-height: 100vh

body
  padding: 0
  margin: 0

.main
  margin: 48px 8px 32px
</style>
