import http from '@/axios/http'

const post = ({ headers, data, url = '/teams' }) => {
  headers = {
    'Content-Type': 'application/json',
    ...headers
  }
  if (localStorage.getItem('token')) {
    headers.Auth = localStorage.getItem('token')
  }

  return http
    .post(
      url,
      data,
      { headers }
    )
    .then(response => response.data)
}

export const create = (team) => {
  return post({
    data: { ...team }
  })
}
