import Vue from 'vue'

const mutations = {
  status: (state, status) => {
    return Vue.set(
      state,
      'status',
      status
    )
  }
}

export default mutations
