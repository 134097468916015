import axios from '@/axios'
import router from '@/router'

const actions = {
  setUser: ({ dispatch, commit, state }, id) => {
    dispatch('main/setLoading', true, { root: true })
    return axios
      .users
      .post
      .get(id)
      .then((response) => {
        let user = { ...response }
        commit('user', user)
        dispatch('setUserEventPermissions', { ...id, event_id: 1 }).then(response => {
          user = { ...user, roles: [...response] }
          commit('user', user)
          return dispatch('main/setLoading', false, { root: true })
        })
      })
      .catch(function (error) {
        console.log('-----------error-----------', error)
        dispatch('main/setLoading', false, { root: true })
      })
  },
  setCleanStore: ({ commit }) =>
    commit('cleanStore'),

  setSignUp: ({ commit, dispatch }, user) => {
    dispatch('main/setLoading', true, { root: true })
    return axios
      .users
      .post
      .create(user)
      .then((response) => {
        if (response.success) {
          commit('userId', response.user_id)
          router.push('/SignIn')
          return dispatch('main/setLoading', false, { root: true })
        } else {
          console.log('-----------error-----------', response)
          dispatch('main/setLoading', false, { root: true })
          return response.success
        }
      })
      .catch(function (error) {
        console.log('-----------error-----------', error)
        dispatch('main/setLoading', false, { root: true })
      })
  },
  setSignIn: ({ commit, dispatch }, user) => {
    dispatch('main/setLoading', true, { root: true })
    return axios
      .users
      .post
      .signIn(user)
      .then((response) => {
        if (response.success) {
          commit('userId', response.user_id)
          localStorage.setItem('token', response.token)
          router.push('/UserProfile')
        } else {
          dispatch('main/setLoading', false, { root: true })
          return response.success
        }
      })
      .catch(function (error) {
        console.log('-----------error-----------', error)
        dispatch('main/setLoading', false, { root: true })
      })
  },
  setUserEventPermissions: ({ commit, dispatch }, ids) => {
    return axios
      .users
      .post
      .getEventRoles(ids)
      .then((response) => {
        return response
      })
      .catch(function (error) {
        console.log('-----------error-----------', error)
        dispatch('main/setLoading', false, { root: true })
      })
  },
  setAddEventRole: ({ commit, dispatch }, info) => {
    dispatch('main/setLoading', true, { root: true })
    return axios
      .users
      .post
      .addEventRole(info)
      .then((response) => {
        return response
      })
      .catch(function (error) {
        console.log('-----------error-----------', error)
        dispatch('main/setLoading', false, { root: true })
      })
  },
  setInitialiseStore: ({ commit }) => {
    return commit('initialiseStore')
  }
}

export default actions
