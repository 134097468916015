import Vue from 'vue'

const mutations = {
  teams: (state, teams) => {
    return Vue.set(
      state,
      'teams',
      teams
    )
  }
}

export default mutations
