import axios from '@/axios'

const actions = {
  setRobots: ({ commit, dispatch }) => {
    dispatch('main/setLoading', true, { root: true })
    return axios
      .robots
      .fetch(
        '/robots'
      )
      .then((response) => {
        commit('robots', response)
        return dispatch('main/setLoading', false, { root: true })
      })
      .catch(function (error) {
        console.log('-----------error-----------', error)
        dispatch('main/setLoading', false, { root: true })
      })
  },
  setCreateRobot: ({ dispatch }, robot) => {
    dispatch('main/setLoading', true, { root: true })
    return axios
      .robots
      .post
      .create(robot)
      .then((response) => {
        return dispatch('main/setLoading', false, { root: true })
      })
      .catch(function (error) {
        console.log('-----------error-----------', error)
        dispatch('main/setLoading', false, { root: true })
      })
  },
  setRobotInspection: ({ dispatch }, robot) => {
    dispatch('main/setLoading', true, { root: true })
    return axios
      .robots
      .post
      .inspection(robot)
      .then((response) => {
        return dispatch('main/setLoading', false, { root: true })
      })
      .catch(function (error) {
        console.log('-----------error-----------', error)
        dispatch('main/setLoading', false, { root: true })
      })
  },
  setRobotImage: ({ dispatch }, robot) => {
    dispatch('main/setLoading', true, { root: true })
    return axios
      .robots
      .post
      .image(robot)
      .then((response) => {
        return dispatch('main/setLoading', false, { root: true })
      })
      .catch(function (error) {
        console.log('-----------error-----------', error)
        dispatch('main/setLoading', false, { root: true })
      })
  },
  setCurrentRobot: ({ commit, dispatch }, robot) =>
    commit('currentRobot', robot)
}

export default actions
