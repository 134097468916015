import Vue from 'vue'

const mutations = {
  robots: (state, robots) => {
    return Vue.set(
      state,
      'robots',
      robots
    )
  },
  currentRobot: (state, robot) => {
    return Vue.set(
      state,
      'currentRobot',
      robot
    )
  }
}

export default mutations
