import Vue from 'vue'
import VueRouter from 'vue-router'
// import store from 'vuex'

Vue.use(VueRouter)

const load = component =>
  () =>
    import(`@/components/templates/${component}.vue`)

const routes = [
  {
    path: '/SignUp',
    name: 'SignUp',
    meta: {
      auth: false,
      withToken: false
    },
    components: {
      content: load('SignUp')
    }
  },
  {
    path: '/SignIn',
    name: 'SignIn',
    meta: {
      auth: false,
      withToken: false
    },
    components: {
      content: load('SignIn')
    }
  },
  {
    path: '/UserProfile',
    name: 'UserProfile',
    meta: {
      auth: false,
      withToken: false
    },
    components: {
      content: load('UserProfile')
    }
  },
  {
    path: '/Robots',
    name: 'Robots',
    meta: {
      auth: false,
      withToken: false
    },
    components: {
      content: load('Robots')
    }
  },
  {
    path: '/InspectRobot',
    name: 'InspectRobot',
    meta: {
      auth: false,
      withToken: false
    },
    components: {
      content: load('InspectRobot')
    }
  },
  {
    path: '/Keys',
    name: 'Keys',
    meta: {
      auth: false,
      withToken: false
    },
    components: {
      content: load('Keys')
    }
  },
  {
    path: '/Fights',
    name: 'Fights',
    meta: {
      auth: false,
      withToken: false
    },
    components: {
      content: load('Fights')
    }
  },
  {
    path: '/CurrentFight',
    name: 'CurrentFight',
    meta: {
      auth: false,
      withToken: false
    },
    components: {
      content: load('CurrentFight')
    }
  },
  {
    path: '/',
    name: '',
    meta: {
      auth: false,
      withToken: false
    },
    components: {
      content: load('UserProfile')
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

// router.beforeEach(
//   (to, from, next) => {
//     if (to.meta.public) {
//       return next()
//     }
//     AuthBeforeEach(to, from, next, router, store)
//   }
// )

export default router
