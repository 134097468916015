import axios from 'axios'

const http = axios.create({
  baseURL: 'https://eventos.ligabrasileiraderobotica.com.br:1880',
  timeout: 1000000
})

export const services = host =>
  axios.create({
    baseURL: host,
    timeout: 1000000
  })

export default http
