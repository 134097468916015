import http from '@/axios/http'

const post = ({ headers, data, url }) => {
  headers = {
    'Content-Type': 'application/json',
    ...headers
  }
  if (localStorage.getItem('token')) {
    headers.Auth = localStorage.getItem('token')
  }

  return http
    .post(
      url,
      data,
      { headers }
    )
    .then(response => response.data)
}

export const create = (robot) => {
  return post({
    url: '/robots',
    data: { ...robot }
  })
}

export const inspection = (robot) => {
  return post({
    url: '/both_inspection',
    data: { ...robot }
  })
}

export const image = (robot) => {
  return post({
    url: '/image_robot',
    data: { ...robot }
  })
}
