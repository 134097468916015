// import Vue from 'vue'

const getters = {
  getFights: ({
    fights
  }) =>
    fights,

  getCurrentFight: ({
    currentFight
  }) =>
    currentFight,

  getCurrentKeys: ({
    currentKeys
  }) =>
    currentKeys
}

export default getters
